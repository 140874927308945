"use client"

import Script from "next/script"

const GoogleCounter = () => {
  if (typeof window == "undefined") return

  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-0B8JZDLCP0"
      ></Script>
      <Script id="gtag">
        {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-0B8JZDLCP0');
      `}
      </Script>
    </>
  )
}

export default GoogleCounter
