"use client"

import { ConfigProvider } from "antd"
import { AntdRegistry } from "@ant-design/nextjs-registry"
import { AppProgressBar as ProgressBar } from "next-nprogress-bar"

import { theme } from "@/config/antd"
import { SessionProvider } from "next-auth/react"
import React, { Suspense } from "react"
import { Session } from "next-auth"
import { setToken } from "@/services/api"
import { userStore } from "@/application/user/store"
import { User } from "@/application/entities/user"
import { Bounce, ToastContainer } from "react-toastify"

type TUIProvider = React.FC<React.PropsWithChildren & IProps>

interface IProps {
  session: Session | null
}

const UIProvider: TUIProvider = ({ session, children }) => {
  if (session) {
    setToken(session.access)
    userStore.activate(session.user as User)
  }

  return (
    <>
      <SessionProvider
        session={session}
        refetchWhenOffline={false}
        refetchOnWindowFocus={false}
      >
        <AntdRegistry>
          <ConfigProvider theme={theme}>
            {children}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
            />
          </ConfigProvider>
        </AntdRegistry>
      </SessionProvider>

      <Suspense>
        <ProgressBar
          height="4px"
          color="#2563eb7d"
          options={{ showSpinner: false, speed: 700 }}
          shallowRouting
        />
      </Suspense>
    </>
  )
}

export default UIProvider
