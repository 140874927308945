import type { ThemeConfig } from "antd"
import { fontConfig } from "@/config/font"

const colors = {
  blue: "#3F88EC",
}

// @ts-ignore - ...
export const theme: ThemeConfig = {
  token: {
    colorPrimary: colors.blue,
    fontFamily: fontConfig.style["fontFamily"],
  },
  components: {
    Button: {
      borderRadius: 93,
      controlHeight: 60,
      paddingInline: 30,
    },
    Dropdown: {
      paddingBlock: 15,
    },
    Steps: {
      dotSize: 11,
      // dotBorderColor: colors.blue,
      // dotActiveBorderColor: colors.blue
    },
    Layout: {
      headerPadding: 0,
      bodyBg: "transparent",
      headerBg: "black",
    },
    Menu: {
      horizontalItemSelectedColor: colors.blue,
      activeBarBorderWidth: 0,
      lineWidth: 0,
    },
    Input: {
      borderRadius: 11,
      colorBorder: "#B2B2B2",
    },
  },
}
