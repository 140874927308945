import { UserView } from "@/application/user/view"
import { action, makeObservable, observable } from "mobx"
import { User } from "@/application/entities/user"

export class UserStore {
  @observable
  view: UserView
  @observable
  user: User

  static make() {
    return new UserStore()
  }

  constructor() {
    makeObservable(this)

    this.view = UserView.make(this)
  }

  @action.bound
  setUser(user: User) {
    this.user = user
  }

  @action.bound
  activate(user: User) {
    this.setUser(user)
  }
}

export const userStore = UserStore.make()
