import axios from "axios"
import { getSession } from "next-auth/react"

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 60000,
})

instance.interceptors.request.use(
  async function (config: any) {
    let token = config.token
    if (!token) {
      const session = await getSession()

      if (session) {
        token = session.access
      }
    }
    if (token && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${token}`
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

export const setToken = (token: string) => {
  client.defaults.token = token
  instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const ERROR_REQUEST_TEXT = "Something went wrong, please try again later"

const ApiClient = () => {
  instance.interceptors.request.use(async (request) => {
    if (request.headers.Authorization) return request

    const session = await getSession()

    if (session) {
      const authHeaderValue = `Bearer ${session.access}`

      request.headers.Authorization = authHeaderValue
      instance.defaults.headers.common.Authorization = authHeaderValue
    }

    return request
  })

  // instance.interceptors.response.use(
  //   (response) => response,
  //   async (error: AxiosError<Record<any, any> & { error?: string }>) => {
  //     // "success"  🚀
  //     // "error"    🤖
  //
  //     if (
  //       typeof window !== "undefined" &&
  //       !(error.config as any).withoutToast
  //     ) {
  //       const serverReason = error.response?.data?.error
  //       const defaultError = `Error, please try again later! ${error.response?.statusText || "Unexpected Error"}`
  //       errorToast(`🤖 ${serverReason || defaultError}`)
  //     }
  //
  //     return Promise.reject(error)
  //   },
  // )

  return instance
}

export const client = ApiClient()

export const HttpRequest = {
  get: client.get,
  post: client.post,
}
