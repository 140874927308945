import { computed, makeObservable, observable } from "mobx"
import { UserStore } from "@/application/user/store"

export class UserView {
  @observable
  store: UserStore

  static make(store: UserStore) {
    return new UserView(store)
  }

  constructor(store: UserStore) {
    makeObservable(this)

    this.store = store
  }

  get isAuthenticated(): boolean {
    return !!this.store.user.id
  }

  @computed
  get name() {
    return this.store.user.name
  }

  @computed
  get email() {
    return this.store.user.email
  }

  @computed
  get phone() {
    return this.store.user.phone
  }

  @computed
  get role() {
    return this.store.user.role
  }
}
